import React from "react";
import {
  Container,
  Divider,
  Dropdown,
  Grid,
  Header,
  Image,
  List,
  Menu,
  Segment,
  Button,
} from "semantic-ui-react";
import classes from "./aboutUs.module.css";
function AboutUs() {
  return (
    <Grid doubling className={classes.AboutUs} columns="2" container>
      <Grid.Column width={8}>
        <Header className={classes.FirstHeader} color="yellow">
          WELCOME TO OUR COMPANY
        </Header>
        <h3>Sanchit Investment</h3>
        <br />
        <p>
          Sanchit Investment is a money managing house associated in 1992 by Mr.
          Rajeev Kumar Agarwal (B.Com, MBA, C.M. Club Member LIC, AMFI
          Certified, UMAX Club Member by UIIC)
        </p>
        <p>
          Sanchit Investment is serving various Life's and dealing in MF,LIC,GIC
          and Health insurance means all financial solution in a roof.
        </p>
        <p>
          At Sanchit Investment we manage your money and planning your financial
          security are no easy tasks.
        </p>
        <br />
        <Button size="huge" circular color="yellow">
          Know More
        </Button>
      </Grid.Column>
      <Grid.Column>
        <Image className={classes.ManImage} src="../images/man.png" />
      </Grid.Column>
    </Grid>
  );
}

export default AboutUs;
