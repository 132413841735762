import React from "react";
import {
  Container,
  Divider,
  Dropdown,
  Grid,
  Header,
  Image,
  List,
  Menu,
  Segment,
  Button,
  Icon,
  Card,
} from "semantic-ui-react";
import classes from "./ServicesPage.module.css";
import { Link } from "react-router-dom";
function ServicesPage() {
  return (
    <div>
      <div className={classes.Overlay}>
        <Container textAlign="center" className={classes.Content}>
          <h3>Services</h3>
          <p>
            A comprehensive range of services including Financial Planning and
            Investment Advisory. Advice on Investments, Insurance, Tax Saving,
            Retirement Planning, Future Planning for Children and more.
          </p>
          <br />
          <h2 style={{ color: "white" }}>
            A wide range of investment options such as:
          </h2>
          <List size="big" style={{ color: "white" }} bulleted>
            <List.Item>Mutual Funds</List.Item>
            <List.Item>
              Life Insurance products like term / endowment plans / money back
            </List.Item>
            <List.Item>
              Health Insurance products offered by life as well as general
              insurance companies.
            </List.Item>
            <List.Item>
              General Insurance products for protection of properties,
              automobiles, households, commercial establishments etc. from
              accidental losses as well as against possible legal liabilities.
            </List.Item>
            <List.Item>Similar other financial products</List.Item>
            <List.Item>Fixed deposit (HDFC Ltd. and Mahindra)</List.Item>
          </List>
        </Container>
      </div>
      <Grid stackable className={classes.ServicesGrid} container relaxed="very">
        <Grid.Row columns={3}>
          <Grid.Column>
            <Card color="yellow">
              <Header attached="top" size="huge" textAlign="center">
                Two Wheeler Insurance
              </Header>
              <Icon name="motorcycle" size="massive" fitted />

              <Card.Content>
                <Card.Header>
                  <List animated>
                    <List.Item>
                      <List.Icon name="arrow right" color="yellow" />
                      <List.Content>
                        <Link
                          to={{
                            pathname:
                              "https://general.bajajallianz.com/MotorInsurance/onlineportal/motorTP/loadData.do?p_product_type=RTW&src=CBM_0563157",
                          }}
                          target="_blank"
                          style={{ color: "black" }}
                        >
                          Two Wheeler TP Policy
                        </Link>
                      </List.Content>
                    </List.Item>
                    <List.Item>
                      <List.Icon name="arrow right" color="yellow" />
                      <List.Content>
                        <Link
                          to={{
                            pathname:
                              "https://general.bajajallianz.com/MotorInsurance/onlineportal/motorNew/index.jsp?src=CBM_0545784",
                          }}
                          target="_blank"
                          style={{ color: "black" }}
                        >
                          Two Wheeler - Package Policy
                        </Link>
                      </List.Content>
                    </List.Item>
                  </List>
                </Card.Header>
              </Card.Content>
            </Card>
          </Grid.Column>
          <Grid.Column>
            <Card color="yellow">
              <Header attached="top" size="huge" textAlign="center">
                Car Insurance
              </Header>
              <Icon name="car" size="massive" fitted />
              <Card.Content>
                <Card.Header>
                  <List animated>
                    <List.Item>
                      <List.Icon name="arrow right" color="yellow" />
                      <List.Content>
                        <Link
                          to={{
                            pathname:
                              "https://general.bajajallianz.com/MotorInsurance/onlineportal/motorNew/indexCar.jsp?p_product_code=1801&src=CBM_0574887",
                          }}
                          target="_blank"
                          style={{ color: "black" }}
                        >
                          Private Car - Package Policy
                        </Link>
                      </List.Content>
                    </List.Item>
                    <List.Item>
                      <List.Icon name="arrow right" color="yellow" />
                      <List.Content>
                        <Link
                          to={{
                            pathname:
                              "https://general.bajajallianz.com/MotorInsurance/onlineportal/motorTP/loadData.do?p_product_type=RPV&src=CBM_0624110",
                          }}
                          target="_blank"
                          style={{ color: "black" }}
                        >
                          Private Car TP Policy
                        </Link>
                      </List.Content>
                    </List.Item>
                  </List>
                </Card.Header>
              </Card.Content>
            </Card>
          </Grid.Column>
          <Grid.Column>
            <Card color="yellow">
              <Header attached="top" size="huge" textAlign="center">
                Health Insurance
              </Header>
              <Icon name="heart" size="massive" fitted />
              <Card.Content>
                <Card.Header>
                  <List animated>
                    <List.Item>
                      <List.Icon name="arrow right" color="yellow" />
                      <List.Content>
                        <Link
                          to={{
                            pathname:
                              "https://general.bajajallianz.com/Insurance/healthGuard/loadHgDtls.do?product=EC&src=CBM_0574886",
                          }}
                          target="_blank"
                          style={{ color: "black" }}
                        >
                          Extra Care Plus
                        </Link>
                      </List.Content>
                    </List.Item>
                    <List.Item>
                      <List.Icon name="arrow right" color="yellow" />
                      <List.Content>
                        <Link
                          to={{
                            pathname:
                              "https://general.bajajallianz.com/BagicNxt/CBMQR/cbmUrlLink.do?imd_code=541E310765344816ADE40700F1F3ED90&sub_imd_code=D57FA696FE053D8E&p_product_code=8407&p_location_code=BC86ED5A0F3E335B&p_agnet_category=25E070F074B76603EAE9E9A86B0F0EC4&p_encr_flag=Y",
                          }}
                          target="_blank"
                          style={{ color: "black" }}
                        >
                          Critical Illness Insurance
                        </Link>
                      </List.Content>
                    </List.Item>
                    <List.Item>
                      <List.Icon name="arrow right" color="yellow" />
                      <List.Content>
                        <Link
                          to={{
                            pathname:
                              "https://general.bajajallianz.com/Insurance/healthGuard/loadHgDtls.do?src=CBM_0564776",
                          }}
                          target="_blank"
                          style={{ color: "black" }}
                        >
                          Health Guard Policy
                        </Link>
                      </List.Content>
                    </List.Item>
                    <List.Item>
                      <List.Icon name="arrow right" color="yellow" />
                      <List.Content>
                        <Link
                          to={{
                            pathname:
                              "https://general.bajajallianz.com/BagicNxt/CBMQR/cbmUrlLink.do?imd_code=541E310765344816ADE40700F1F3ED90&sub_imd_code=D57FA696FE053D8E&p_product_code=8442&p_location_code=BC86ED5A0F3E335B&p_agnet_category=25E070F074B76603EAE9E9A86B0F0EC4&p_encr_flag=Y",
                          }}
                          target="_blank"
                          style={{ color: "black" }}
                        >
                          Health Infinity Policy
                        </Link>
                      </List.Content>
                    </List.Item>
                  </List>
                </Card.Header>
              </Card.Content>
            </Card>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={3}>
          <Grid.Column>
            <Card color="yellow">
              <Header attached="top" size="huge" textAlign="center">
                Personal Accident
              </Header>
              <Icon name="dont" size="massive" fitted />
              <Card.Content>
                <Card.Header>
                  <List animated>
                    <List.Item>
                      <List.Icon name="arrow right" color="yellow" />
                      <List.Content>
                        <Link
                          to={{
                            pathname:
                              "https://general.bajajallianz.com/BagicNxt/CBMQR/cbmUrlLink.do?imd_code=541E310765344816ADE40700F1F3ED90&sub_imd_code=D57FA696FE053D8E&p_product_code=6403&p_location_code=BC86ED5A0F3E335B&p_agnet_category=25E070F074B76603EAE9E9A86B0F0EC4&p_encr_flag=Y",
                          }}
                          target="_blank"
                          style={{ color: "black" }}
                        >
                          Premium Personal Guard
                        </Link>
                      </List.Content>
                    </List.Item>
                  </List>
                </Card.Header>
              </Card.Content>
            </Card>
          </Grid.Column>
          <Grid.Column>
            <Card color="yellow">
              <Header attached="top" size="huge" textAlign="center">
                Travel Insurance
              </Header>
              <Icon name="travel" size="massive" fitted />
              <Card.Content>
                <Card.Header>
                  <List animated>
                    <List.Item>
                      <List.Icon name="arrow right" color="yellow" />
                      <List.Content>
                        <Link
                          to={{
                            pathname:
                              "https://www.bajajallianz.com/travel-insurance-online/buy-online.html?src=CBM_0564777",
                          }}
                          target="_blank"
                          style={{ color: "black" }}
                        >
                          Travel Companion
                        </Link>
                      </List.Content>
                    </List.Item>
                  </List>
                </Card.Header>
              </Card.Content>
            </Card>
          </Grid.Column>
          <Grid.Column>
            <Card color="yellow">
              <Header attached="top" size="huge" textAlign="center">
                Cyber Insurance
              </Header>
              <Icon name="laptop" size="massive" fitted />
              <Card.Content>
                <Card.Header>
                  <List animated>
                    <List.Item>
                      <List.Icon name="arrow right" color="yellow" />
                      <List.Content>
                        <Link
                          to={{
                            pathname:
                              "https://general.bajajallianz.com/BagicNxt/CBMQR/cbmUrlLink.do?imd_code=541E310765344816ADE40700F1F3ED90&sub_imd_code=D57FA696FE053D8E&p_product_code=3313&p_location_code=BC86ED5A0F3E335B&p_agnet_category=25E070F074B76603EAE9E9A86B0F0EC4&p_encr_flag=Y",
                          }}
                          target="_blank"
                          style={{ color: "black" }}
                        >
                          Individual Cyber Safe
                        </Link>
                      </List.Content>
                    </List.Item>
                  </List>
                </Card.Header>
              </Card.Content>
            </Card>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  );
}

export default ServicesPage;
