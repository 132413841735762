import React from "react";

function WhyChooseUs() {
  return (
    <section id="why_us">
      <div class="container text-center">
        <div class="row">
          <div class="col-md-8 col-md-offset-2">
            <div class="head_title">
              <h2>WHY CHOOSE US?</h2>
              <p>
                At Sanchit Investment we manage your money and planning your
                financial security are no easy tasks. Time constraints, tax laws
                that are constantly changing and a confusing assortment of
                investment options - all present roadblocks for most people
                seeking to manage their finances in a profitable way
              </p>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-3 col-sm-6">
            <div class="why_us_item">
              <span class="fa fa-leaf"></span>
              <h4>We deliver quality</h4>
            </div>
          </div>
          <div class="col-md-3 col-sm-6">
            <div class="why_us_item">
              <span class="fa fa-futbol-o">
                <i class="fas fa-alarm-clock"></i>
              </span>
              <h4>Always on time</h4>
            </div>
          </div>
          <div class="col-md-3 col-sm-6">
            <div class="why_us_item">
              <span class="fa fa-group"></span>
              <h4>We are pasionate</h4>
            </div>
          </div>
          <div class="col-md-3 col-sm-6">
            <div class="why_us_item">
              <span class="fa fa-line-chart"></span>
              <h4>Professional Services</h4>
            </div>
          </div>
        </div>
      </div>
    </section> /* <!-- Why us end --> */
  );
}

export default WhyChooseUs;
