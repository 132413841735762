import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import {
  Container,
  Divider,
  Dropdown,
  Grid,
  Header,
  Image,
  List,
  Menu,
  Segment,
  Button,
  Icon,
} from "semantic-ui-react";
import classes from "./ImageSliderCarousel.module.css";
function ImageSliderCarousel() {
  return (
    <Grid>
      <Carousel
        showThumbs={false}
        autoPlay
        interval={3200}
        transitionTime={500}
        infiniteLoop
      >
        <div>
          <img alt="" src="../images/slider_img.jpg" />
          <div className={classes.Overlay}>
            <div className={classes.Content}>
              <h3>Welcome to the Profession of</h3>
              <h1>Life Insurance Consultant</h1>
              <Button size="big" circular color="yellow">
                KNOW MORE
              </Button>
            </div>
          </div>
        </div>
        <div>
          <img alt="" src="../images/slider_img2.jpg" />
          <div className={classes.Overlay}>
            <div className={classes.Content}>
              <h1>Life Insurance</h1>
              <h3>
                The older you get The more it costs so take decision today only
              </h3>
              <Button size="big" circular color="yellow">
                KNOW MORE
              </Button>
            </div>
          </div>
        </div>
        <div>
          <img alt="" src="../images/slider_img3.jpg" />
          <div className={classes.Overlay}>
            <div className={classes.Content}>
              <h3>
                No man is rich enough to buy back his past but he can plan for
                future by acting today
              </h3>
              <Button size="big" circular color="yellow">
                KNOW MORE
              </Button>
            </div>
          </div>
        </div>
      </Carousel>
    </Grid>
  );
}

export default ImageSliderCarousel;
