import React from "react";
import classes from "./WhyUs.module.css";
import MediaQuery from "react-responsive";
import {
  Container,
  Divider,
  Dropdown,
  Grid,
  Header,
  Image,
  List,
  Menu,
  Segment,
  Button,
  Icon,
} from "semantic-ui-react";
function WhyUs() {
  return (
    <div className={classes.Container}>
      <Grid stackable container relaxed="very">
        <Grid.Row centered>
          <Header size="huge">WHY CHOOSE US?</Header>
          <Header>
            At Sanchit Investment we manage your money and planning your
            financial security are no easy tasks. Time constraints, tax laws
            that are constantly changing and a confusing assortment of
            investment options - all present roadblocks for most people seeking
            to manage their finances in a profitable way
          </Header>
        </Grid.Row>
        <Grid.Row textAlign="center" className={classes.CircleItemsRow}>
          <Grid.Column width={4}>
            <div className={classes.CircleItem}>
              <Icon size="large" name="leaf" />
            </div>
            <Header className={classes.Header} size="huge">
              We deliver quality
            </Header>
          </Grid.Column>
          <Grid.Column width={4}>
            <div className={classes.CircleItem}>
              <Icon size="large" name="soccer" />
            </div>
            <Header className={classes.Header} size="huge">
              Always on time
            </Header>
          </Grid.Column>
          <Grid.Column width={4}>
            <div className={classes.CircleItem}>
              <Icon size="large" name="users" />
            </div>
            <Header className={classes.Header} size="huge">
              We are pasionate
            </Header>
          </Grid.Column>
          <Grid.Column width={4}>
            <div className={classes.CircleItem}>
              <Icon size="large" name="line graph" />
            </div>
            <Header className={classes.Header} size="huge">
              Professional Services
            </Header>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  );
}

export default WhyUs;
