import React from "react";
import { Card, Grid, Image, Icon, Button, Header } from "semantic-ui-react";
import liclogo from "../icons/lic.png";
import hdfclogo from "../icons/hdfc.png";
import bajajlogo from "../icons/bajaj.png";
import mutuallogo from "../icons/mutual.png";
import hdfcLifelogo from "../icons/hdfcLife.png";
import unitedlogo from "../icons/united.png";
import tatalogo from "../icons/tata.png";
import sbilogo from "../icons/sbi.png";
import starlogo from "../icons/star.png";
import hdfcBanklogo from "../icons/hdfcBank.png";
import pnblogo from "../icons/pnb.png";
import mahindralogo from "../icons/mahindra.png";
import classes from "./OurPartners.module.css";
import { Link } from "react-router-dom";
function OurPartners() {
  return (
    <div className={classes.OurPartners}>
      <Grid container stackable>
        <Grid.Row centered>
          <Header
            style={{
              color: "white",
              "font-size": "40px",
              "margin-bottom": "20px",
              "margin-top": "10px",
            }}
            size="huge"
          >
            Our Partners
          </Header>
        </Grid.Row>
        <Grid.Row columns="3">
          <Grid.Column>
            <Card color="yellow">
              <Image src={liclogo} />
              <Card.Content textAlign="center">
                <Link
                  to={{
                    pathname: "https://www.licindia.in/Home/Pay-Premium-Online",
                  }}
                  target="_blank"
                >
                  <Button
                    style={{ "margin-bottom": "10px" }}
                    size="big"
                    circular
                    color="yellow"
                  >
                    ONLINE RENEW
                  </Button>
                </Link>
                <Link
                  to={{
                    pathname: "https://www.licindia.in/Products/Insurance-Plan",
                  }}
                  target="_blank"
                >
                  <Button size="big" circular color="yellow">
                    PLAN DETAILS
                  </Button>
                </Link>
              </Card.Content>
            </Card>
          </Grid.Column>
          <Grid.Column>
            <Card raised color="yellow">
              <Image fluid src={hdfclogo} />
              <Card.Content textAlign="center">
                <Link
                  to={{
                    pathname: "https://www.licindia.in/Products/Insurance-Plan",
                  }}
                  target="_blank"
                >
                  <Button
                    style={{ "margin-bottom": "10px" }}
                    size="big"
                    circular
                    color="yellow"
                  >
                    ONLINE RENEW
                  </Button>
                </Link>
                <Link
                  to={{
                    pathname:
                      "https://www.apollomunichinsurance.com/health-insurance-plans.aspx",
                  }}
                  target="_blank"
                >
                  <Button size="big" circular color="yellow">
                    PLAN DETAILS
                  </Button>
                </Link>
              </Card.Content>
            </Card>
          </Grid.Column>
          <Grid.Column>
            <Card color="yellow">
              <Image src={bajajlogo} />
              <Card.Content textAlign="center">
                <Link
                  to={{
                    pathname: "https://bagic.page.link/BcsLNz",
                  }}
                  target="_blank"
                >
                  <Button
                    style={{ "margin-bottom": "10px" }}
                    size="big"
                    circular
                    color="yellow"
                  >
                    ONLINE RENEW
                  </Button>
                </Link>
              </Card.Content>
            </Card>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row columns="3">
          <Grid.Column>
            <Card color="yellow">
              <Image src={mutuallogo} />
              <Card.Content textAlign="center">
                <Link
                  to={{
                    pathname:
                      "http://mf.sanchitinvestment.in/software/custlogin.asp",
                  }}
                  target="_blank"
                >
                  <Button
                    style={{ "margin-bottom": "10px" }}
                    size="big"
                    circular
                    color="yellow"
                  >
                    LOGIN FOR MUTUAL FUNDS
                  </Button>
                </Link>
              </Card.Content>
            </Card>
          </Grid.Column>
          <Grid.Column>
            <Card color="yellow">
              <Image fluid src={hdfcLifelogo} />
              <Card.Content textAlign="center">
                <Link
                  to={{
                    pathname:
                      "https://onlinepayments.hdfclife.com/HDFCLife/quick_pay.html",
                  }}
                  target="_blank"
                >
                  <Button
                    style={{ "margin-bottom": "10px" }}
                    size="big"
                    circular
                    color="yellow"
                  >
                    ONLINE RENEW
                  </Button>
                </Link>
                <Link
                  to={{
                    pathname:
                      "https://brandsite-static.hdfclife.com/media/iwov-resources/HDFC-Click2protect-Plus-brochure.pdf",
                  }}
                  target="_blank"
                >
                  <Button size="big" circular color="yellow">
                    PLAN DETAILS
                  </Button>
                </Link>
              </Card.Content>
            </Card>
          </Grid.Column>
          <Grid.Column>
            <Card color="yellow">
              <Image src={unitedlogo} />
              <Card.Content textAlign="center">
                <Link
                  to={{
                    pathname:
                      "https://portal.uiic.in/GCWebPortal/healthPolicyCore/initiateRenewalAction.do?USERTYPE=CUSTOMER&Reg=N&ENTERPRISE=TRUE",
                  }}
                  target="_blank"
                >
                  <Button
                    style={{ "margin-bottom": "10px" }}
                    fluid
                    size="big"
                    circular
                    color="yellow"
                  >
                    HEALTH POLICY RENEW
                  </Button>
                </Link>
                <Link
                  to={{
                    pathname:
                      "https://portal.uiic.in/GCWebPortal/CoreMotor/initiateRenewal.do?USERTYPE=CUSTOMER&reg=N&ENTERPRISE=TRUE",
                  }}
                  target="_blank"
                >
                  <Button fluid size="big" circular color="yellow">
                    MOTOR POLICY RENEW
                  </Button>
                </Link>
              </Card.Content>
            </Card>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row columns="3">
          <Grid.Column>
            <Card color="yellow">
              <Image src={tatalogo} />
              <Card.Content textAlign="center">
                <Link
                  to={{
                    pathname: "https://www.tataaig.com/renew-insurance",
                  }}
                  target="_blank"
                >
                  <Button
                    style={{ "margin-bottom": "10px" }}
                    size="big"
                    circular
                    color="yellow"
                  >
                    ONLINE RENEW
                  </Button>
                </Link>
              </Card.Content>
            </Card>
          </Grid.Column>
          <Grid.Column>
            <Card color="yellow">
              <Image fluid src={sbilogo} />
              <Card.Content textAlign="center">
                <Link
                  to={{
                    pathname: "https://www.sbigeneral.in/portal/policy-renewal",
                  }}
                  target="_blank"
                >
                  <Button
                    style={{ "margin-bottom": "10px" }}
                    size="big"
                    circular
                    color="yellow"
                  >
                    ONLINE RENEW
                  </Button>
                </Link>
              </Card.Content>
            </Card>
          </Grid.Column>
          <Grid.Column>
            <Card color="yellow">
              <Image src={starlogo} />
              <Card.Content textAlign="center">
                <Link
                  to={{
                    pathname:
                      "https://www.starhealth.in/customerportal/instant-renewal",
                  }}
                  target="_blank"
                >
                  <Button
                    style={{ "margin-bottom": "10px" }}
                    size="big"
                    circular
                    color="yellow"
                  >
                    ONLINE RENEW
                  </Button>
                </Link>
              </Card.Content>
            </Card>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row columns="3">
          <Grid.Column>
            <Card color="yellow">
              <Image src={hdfcBanklogo} />
              <Card.Content textAlign="center">
                <Link
                  to={{
                    pathname:
                      "https://www.hdfc.com/sites/default/files/2021-02/Individual%20-%20Regular%20Form%20wef%20February%2013%2C%202021.pdf",
                  }}
                  target="_blank"
                >
                  <Button
                    style={{ "margin-bottom": "10px" }}
                    size="big"
                    circular
                    color="yellow"
                  >
                    FIXED DEPOSIT
                  </Button>
                </Link>
                <Link
                  to={{
                    pathname:
                      "https://www.hdfc.com/sites/default/files/2020-06/know-your-customer-kyc-form-g096-individual-off-line-mode-gp.pdf",
                  }}
                  target="_blank"
                >
                  <Button size="big" circular color="yellow">
                    KYC FORM
                  </Button>
                </Link>
              </Card.Content>
            </Card>
          </Grid.Column>
          <Grid.Column>
            <Card raised color="yellow">
              <Image fluid src={pnblogo} />
              <Card.Content textAlign="center">
                <Link
                  to={{
                    pathname:
                      "https://www.pnbhousing.com/wp-content/uploads/2020/07/pnb-fixed-deposit-form.pdf",
                  }}
                  target="_blank"
                >
                  <Button
                    style={{ "margin-bottom": "10px" }}
                    size="big"
                    circular
                    color="yellow"
                  >
                    FIXED DEPOSIT
                  </Button>
                </Link>
                <Link
                  to={{
                    pathname:
                      "https://www.pnbhousing.com/wp-content/uploads/2014/12/Form15G.pdf",
                  }}
                  target="_blank"
                >
                  <Button
                    style={{ "margin-bottom": "10px" }}
                    size="big"
                    circular
                    color="yellow"
                  >
                    FORM 15G
                  </Button>
                </Link>
                <Link
                  to={{
                    pathname:
                      "https://www.pnbhousing.com/wp-content/uploads/2014/12/Form15H.pdf",
                  }}
                  target="_blank"
                >
                  <Button size="big" circular color="yellow">
                    FORM 15H
                  </Button>
                </Link>
              </Card.Content>
            </Card>
          </Grid.Column>
          <Grid.Column>
            <Card color="yellow">
              <Image src={mahindralogo} />
              <Card.Content textAlign="center">
                <Link
                  to={{
                    pathname:
                      "https://mahindrafinance.com/media/124264/samruddhi-application.pdf",
                  }}
                  target="_blank"
                >
                  <Button
                    style={{ "margin-bottom": "10px" }}
                    size="big"
                    circular
                    color="yellow"
                  >
                    FIXED DEPOSIT
                  </Button>
                </Link>
              </Card.Content>
            </Card>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  );
}

export default OurPartners;
