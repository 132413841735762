import React from "react";

function GarbageFooter() {
  return (
    <footer>
      {/* <!-- Footer top --> */}
      <div class="container footer_top">
        <div class="row">
          <div class="col-lg-4 col-sm-7">
            <div class="footer_item">
              <h4>About Company</h4>

              <p>
                Sanchit Investment is a money managing house associated in 1992
                by Mr. Rajeev Kumar Agarwal (B.Com, MBA, C.M. Club Member LIC,
                AMFI Certified, UMAX Club Member by UIIC)
              </p>

              <ul class="list-inline footer_social_icon">
                <li>
                  <a href="">
                    <span class="fa fa-facebook"></span>
                  </a>
                </li>
                <li>
                  <a href="">
                    <span class="fa fa-twitter"></span>
                  </a>
                </li>
                {/* <!--<li><a href=""><span class="fa fa-behance"></span></a></li>
              <li><a href=""><span class="fa fa-dribbble"></span></a></li>--> */}
                <li>
                  <a href="">
                    <span class="fa fa-linkedin"></span>
                  </a>
                </li>
                <li>
                  <a href="">
                    <span class="fa fa-youtube"></span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-lg-2 col-sm-5">
            <div class="footer_item">
              <h4>Explore link</h4>
              <ul class="list-unstyled footer_menu">
                <li>
                  <a href="index.html">
                    <span class="fa fa-play"></span> Home
                  </a>
                </li>
                <li>
                  <a href="about.html">
                    <span class="fa fa-play"></span> About Us
                  </a>
                </li>
                <li>
                  <a href="online.html">
                    <span class="fa fa-play"></span> Online Investment
                  </a>
                </li>
                <li>
                  <a href="services.html">
                    <span class="fa fa-play"></span> Services
                  </a>
                </li>
                <li>
                  <a href="contact.html">
                    <span class="fa fa-play"></span> Contact Us
                  </a>
                </li>
              </ul>
            </div>
          </div>

          <div class="col-lg-6 col-sm-12">
            <div class="col-lg-6 col-sm-6">
              <div class="footer_item">
                <h4>Contact us</h4>
                <ul class="list-unstyled footer_contact">
                  <li>
                    <a href="">
                      <span class="fa fa-map-marker"></span>
                      <p>12/474,Sector-12,Indira Nagar Lucknow-226016 </p>
                    </a>
                  </li>
                  <li>
                    <a href="">
                      <span class="fa fa-envelope"></span> rajeevrk30@gmail.com
                    </a>
                  </li>
                  <li>
                    <a href="">
                      <span class="fa fa-mobile"></span>
                      <p>
                        9839039819
                        <br />
                        7007541284
                      </p>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-lg-6 col-sm-6">
              <div class="footer_item">
                <h4>Branch Office</h4>
                <ul class="list-unstyled footer_contact">
                  <li>
                    <a href="">
                      <span class="fa fa-map-marker"></span>{" "}
                      <p>
                        T-11/ 204 ,Bhagiratha Enclave Awadh Vihar Yojna Shahid
                        Path,Lucknow
                      </p>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Footer top end --> */}

      {/*  <!-- Footer bottom --> */}
      <div class="footer_bottom text-center">
        <p class="wow fadeInRight">
          Copyright &copy; 2020 Sanchit Investment - Powered By -
          <a href="http://www.blissinfosoft.com">BLISS Infosoft</a>
          All Rights Reserved
        </p>
      </div>
      {/* <!-- Footer bottom end --> */}
    </footer>
  );
}

export default GarbageFooter;
