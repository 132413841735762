import React from "react";
import { Carousel } from "react-responsive-carousel";
import {
  Container,
  Divider,
  Dropdown,
  Grid,
  Header,
  Image,
  List,
  Menu,
  Segment,
  Button,
  Icon,
} from "semantic-ui-react";
import classes from "./AboutUsPage.module.css";
function AboutUsPage() {
  return (
    <div>
      <div className={classes.Overlay}>
        <Container textAlign="center" className={classes.Content}>
          <h3>About Us</h3>
          <p>
            Sanchit Investment is a money managing house associated in 1992 by
            Mr. Rajeev Kumar Agarwal (B.Com, MBA, C.M. Club Member LIC,AMFI
            Certified, UMAX Club Member by UIIC) Sanchit Investment is serving
            various Life's and dealing in MF,LIC,GIC and Health insurance means
            all financial solution in a roof. At Sanchit Investment we manage
            your money and planning your financial security are no easy tasks.
            Time constraints, tax laws that are constantly changing and a
            confusing assortment of investment options - all present roadblocks
            for most people seeking to manage their finances in a profitable
            way.As an experienced private investment advisor, we are able to
            offer high - performance financial products that help you take right
            financial decisions. At Sanchit Investment, you take the first step
            towards ensuring financial success. Your Benefits with Sanchit
            Investment you are never just a single individual looking to grow
            your wealth. Because Sanchit Investment is a little more than just a
            wealth advisory service. We are a partner. A partner who disburse
            the time to understand you and your needs. It's a partner who takes
            ownership of your financial goals and does everything it can to
            ensure you reach them. We stretch out to our privileged clients in
            more ways than one, so all your investment related operational
            issues will no longer be a bother. We understand that every client
            comes to us with her or his own unique concerns, needs and financial
            situation. Often, our client's worries are aggravated because there
            doesn't seem to be enough hours in the day to stay on top of
            everything. Yet, there is the recognition that one's financial well-
            being is tied to constantly fluid capital markets, ever-changing tax
            and pension laws and an endless proliferation of product choices.
            Sorting fact from fiction in the overwhelming din of information now
            available just adds to the stress.
          </p>
        </Container>
      </div>
    </div>
  );
}

export default AboutUsPage;
