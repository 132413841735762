import React from "react";
import {
  Container,
  Divider,
  Dropdown,
  Grid,
  Header,
  Image,
  List,
  Menu,
  Segment,
  Button,
  Icon,
} from "semantic-ui-react";
import classes from "./OurServices.module.css";
function OurServices() {
  return (
    <Grid stackable container className={classes.Services}>
      <Grid.Row centered>
        <Header size="huge">Our Services</Header>
      </Grid.Row>
      <Grid.Row columns="3">
        <Grid.Column>
          <Image src="../images/service_img2.jpg" />
          <Header size="large">INSURANCE</Header>
          <Header.Subheader>
            Almost each one of us has bought or plans to buy life insurance. The
            usualprocess is contact an insurance agent who helps you estimate
            the policy value and goes ahead and buys the policy on your behalf.
          </Header.Subheader>
          <Button className={classes.Button} size="big" circular color="yellow">
            KNOW MORE
          </Button>
        </Grid.Column>
        <Grid.Column>
          <Image src="../images/service_img3.jpg" />
          <Header size="large">INVESTMENT</Header>
          <Header.Subheader>
            How much life insurance should you buy? The answer to that question
            is 10 to 15 times your income.You'll need to evaluate the reason
            you're buying life insurance in order to properly answer this
            question.
          </Header.Subheader>
          <Button className={classes.Button} size="big" circular color="yellow">
            KNOW MORE
          </Button>
        </Grid.Column>
        <Grid.Column>
          <Image src="../images/service_img1.jpg" />
          <Header size="large">MUTUAL FUNDS</Header>
          <Header.Subheader>
            A mutual fund is not an alternative investment option to stocks and
            bonds, rather it pools the money of several investors and invests
            this in stocks, bonds, money market instruments and other types of
            securities.
          </Header.Subheader>
          <Button className={classes.Button} size="big" circular color="yellow">
            KNOW MORE
          </Button>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
}

export default OurServices;
