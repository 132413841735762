import React from "react";
import {
  Container,
  Divider,
  Dropdown,
  Grid,
  Header,
  Image,
  List,
  Menu,
  Segment,
} from "semantic-ui-react";
import Footer from "../Footer/Footer";
import BottomNavBar from "./BottomNavBar";

import classes from "./navBar.module.css";
import SecondNavBar from "./SecondNavBar";
function TopNavBar() {
  return (
    <div>
      <nav className={classes.Top_nav}>
        <List size="big" className={classes.Contact_List} floated="left">
          <List.Item icon="phone" content="9839039819" />
          <List.Item icon="mail" content="rajeevrk30@gmail.com" />
          <List.Item
            icon="clock"
            content=" Mon - Sat 9:30 AM- 06:00
                PM"
          />

          <List.Content size="big" className={classes.SocialWebsite_List}>
            <List.Item icon="facebook official" />
            <List.Item icon="twitter" />
            <List.Item icon="linkedin" />
            <List.Item icon="youtube" />
          </List.Content>
        </List>
      </nav>

      {/* <SecondNavBar /> */}
      {/*  <Footer /> */}
    </div>
  );
}

export default TopNavBar;
