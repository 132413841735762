import React from "react";
import { Carousel } from "react-responsive-carousel";
import {
  Container,
  Divider,
  Dropdown,
  Grid,
  Header,
  Image,
  List,
  Menu,
  Segment,
  Button,
  Icon,
} from "semantic-ui-react";
import ContactUsForm from "./ContactUsForm";
import classes from "./ContactUsPage.module.css";
function ContactUsPage() {
  return (
    <div>
      <div className={classes.Overlay}>
        <Container textAlign="center" className={classes.Content}>
          <h3>Contact Us</h3>
        </Container>
        <Grid stackable container>
          <Grid.Column width={10}>
            <Header
              style={{ color: "white", "padding-bottom": "10px" }}
              content="Contact Us"
              size="huge"
            />
            <List relaxed="very" size="big">
              <List.Item
                icon="map marker alternate"
                content="12/474,Sector-12,Indira Nagar Lucknow-226016"
                style={{ color: "white" }}
              />
              <List.Item
                icon="mail"
                content="rajeevrk30@gmail.com"
                style={{ color: "white" }}
              />
              <List.Item
                icon="phone"
                content="9839039819 , 7007541284"
                style={{ color: "white" }}
              />
            </List>
          </Grid.Column>
          <Grid.Column width={5}>
            <Header
              style={{ color: "white", "padding-bottom": "10px" }}
              content="Branch Office"
              size="huge"
            />
            <List relaxed size="large" link>
              <List.Item
                icon="map marker alternate"
                content="T-11/ 204 ,Bhagiratha Enclave Awadh Vihar Yojna Shahid
                        Path,Lucknow"
                style={{ color: "white" }}
              />
            </List>
          </Grid.Column>
        </Grid>
      </div>
      <ContactUsForm />
    </div>
  );
}

export default ContactUsPage;
