import React, { useState } from "react";
import emailjs from "emailjs-com";
import classes from "./ContactUsForm.module.css";
import { Button, Form, Grid, Input, Label, TextArea } from "semantic-ui-react";
function ContactUsForm() {
  const [mobile, setmobile] = useState("");
  const [isError, setIsError] = useState(false);

  function sendEmail(e) {
    e.preventDefault();

    emailjs
      .sendForm(
        "san",
        "SanchitInvestmentTemp",
        e.target,
        "user_dGDwbnmrQCiUCvb5WSBP2"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    e.target.reset();
  }

  return (
    <div className={classes.ContactForm}>
      <Grid stackable container>
        <Grid.Column width={8}>
          <div className={classes.Header}>
            <h2 className={classes.First_Heading}>
              Do you have any questions?
            </h2>
            <h2 className={classes.Second_Heading}>Feel free to contact us!</h2>
          </div>
        </Grid.Column>
        <Grid.Column width={8}>
          <Form onSubmit={sendEmail}>
            <Form.Group widths="equal">
              <Form.Field>
                <input
                  focus
                  type="text"
                  required="required"
                  name="name"
                  placeholder="Name"
                />
              </Form.Field>
              <Form.Field>
                <input
                  focus
                  type="email"
                  name="email"
                  required="required"
                  placeholder="Email"
                />
              </Form.Field>
              <Form.Field>
                <input
                  focus
                  rows={1}
                  type="tel"
                  name="Mobile_Number"
                  placeholder="Mobile_Number"
                  pattern="^\d{10}$"
                  required="required"
                />
              </Form.Field>
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Field>
                <textarea
                  rows={1}
                  required="required"
                  name="message"
                  placeholder="Message"
                />
              </Form.Field>
            </Form.Group>
            <Button color="grey" type="submit">
              Send
            </Button>
          </Form>
        </Grid.Column>
      </Grid>
    </div>
  );
}

export default ContactUsForm;
