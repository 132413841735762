import React, { useState, useEffect, Component } from "react";
import "./App.css";
import MediaQuery from "react-responsive";
import Footer from "./Footer/Footer";
import GarbageFooter from "./Footer/GarbageFooter";
import AboutUs from "./HomePage/AboutUs";
import GarbageAboutUs from "./HomePage/GarbageAboutUs";
import ImageSlider from "./HomePage/ImageSlider";
import ImageSliderCarousel from "./HomePage/ImageSliderCarousel";
import OurServices from "./HomePage/OurServices";
import WhyChooseUs from "./HomePage/WhyChooseUs";
import WhyUs from "./HomePage/WhyUs";
import BottomNavBar from "./NavBar/BottomNavBar";
import NavBar from "./NavBar/NavBar";
import TopNavBar from "./NavBar/TopNavBar";
import { Route, BrowserRouter, Switch } from "react-router-dom";

import Preloader from "./Preloader";
import AboutUsPage from "./AboutUsPage/AboutUsPage";
import ContactUsPage from "./ContactUsPage/ContactUsPage";
import Portfolio from "./HomePage/Portfolio";
import ServicesPage from "./ServicesPage/ServicesPage";
import ContactUsForm from "./ContactUsPage/ContactUsForm";
import OurPartners from "./Online Investment/OurPartners";
import SideDrawerMenu from "./SideDrawer/SideDrawerMenu";
import Backdrop from "./Backdrop/Backdrop";
import { render } from "@testing-library/react";

class App extends Component {
  state = {
    sideDrawerOpen: false,
  };
  drawerToogleClickHandler = () => {
    this.setState((prevState) => {
      return { sideDrawerOpen: !prevState.sideDrawerOpen };
    });
  };

  backdropToggleClickHandler = () => {
    this.setState({ sideDrawerOpen: false });
  };

  render() {
    let sideDrawer;
    let backdrop;

    if (this.state.sideDrawerOpen) {
      backdrop = <Backdrop click={this.backdropToggleClickHandler} />;
    }
    return (
      <div style={{ height: "100%" }}>
        <BrowserRouter>
          {/*  <TopNavBar /> */}
          <BottomNavBar drawerClickHandler={this.drawerToogleClickHandler} />
          <SideDrawerMenu
            show={this.state.sideDrawerOpen}
            hideDrawer={this.backdropToggleClickHandler}
          />
          {backdrop}
          <Switch id="page-wrap">
            <Route exact path={"/"}>
              <ImageSliderCarousel />
              {/* <ImageSlider /> */}
              <AboutUs />
              <WhyUs />
              <OurServices />
              <OurPartners />
              <ContactUsForm />
            </Route>
            <Route exact path={"/AboutUs"}>
              <AboutUsPage />
            </Route>
            <Route exact path={"/ContactUs"}>
              <ContactUsPage />
            </Route>
            <Route exact path={"/Services"}>
              <ServicesPage />
            </Route>
          </Switch>
          {/*  <Portfolio /> */}

          <Footer />
        </BrowserRouter>
      </div>
    );
  }
}

export default App;
