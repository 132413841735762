import React from "react";
import classes from "./bottomNavBar.module.css";
import MediaQuery from "react-responsive";
import {
  Container,
  Divider,
  Dropdown,
  Grid,
  Header,
  Image,
  List,
  Menu,
  Segment,
  Button,
  Icon,
  Sidebar,
  Checkbox,
} from "semantic-ui-react";
import { Link } from "react-router-dom";
import SideBar from "./SideBar";
function BottomNavBar(props) {
  const [visible, setVisible] = React.useState(false);
  return (
    <Grid stackable>
      <nav className={classes.Bottom_Nav}>
        <MediaQuery maxDeviceWidth={1139}>
          <Grid.Column>
            <button
              className={classes.SideDrawerButton}
              onClick={props.drawerClickHandler}
            >
              <Icon size="big" inverted name="bars"></Icon>
            </button>
          </Grid.Column>
        </MediaQuery>

        <Grid.Column>
          <MediaQuery minDeviceWidth={501}>
            <Link to="/">
              <Image
                size="medium"
                bordered
                spaced="left"
                src="images/logo.PNG"
                className={classes.Logo_Img}
              ></Image>
            </Link>
          </MediaQuery>
          <MediaQuery maxDeviceWidth={500}>
            <Link to="/">
              <Image
                size="small"
                bordered
                spaced="left"
                src="images/logo.PNG"
                className={classes.Logo_Img}
              ></Image>
            </Link>
          </MediaQuery>
        </Grid.Column>
        <MediaQuery minDeviceWidth={1140}>
          <Grid.Column floated="right" only="widescreen">
            <List
              size="big"
              horizontal
              items
              link
              className={classes.Nav_Links}
              floated="right"
            >
              <List.Item>
                <Link className={classes.Items} to="/">
                  Home
                </Link>
              </List.Item>

              <List.Item className={classes.Items}>
                <List.Content>
                  <List.Item as="a">
                    <Link className={classes.Services} to="/Services">
                      Services
                    </Link>
                  </List.Item>
                  <Dropdown floating>
                    <Dropdown.Menu>
                      <Dropdown.Item>
                        <Dropdown
                          text="Two wheeler Insurance"
                          simple
                          direction="right"
                        >
                          <Dropdown.Menu>
                            <Dropdown.Item>
                              <Link
                                to={{
                                  pathname:
                                    "https://general.bajajallianz.com/MotorInsurance/onlineportal/motorTP/loadData.do?p_product_type=RTW&src=CBM_0563157",
                                }}
                                target="_blank"
                                style={{ color: "black" }}
                              >
                                Two Wheeler TP Policy
                              </Link>
                            </Dropdown.Item>
                            <Dropdown.Item>
                              <Link
                                to={{
                                  pathname:
                                    "https://general.bajajallianz.com/MotorInsurance/onlineportal/motorNew/index.jsp?src=CBM_0545784",
                                }}
                                target="_blank"
                                style={{ color: "black" }}
                              >
                                Two Wheeler - Package Policy
                              </Link>
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </Dropdown.Item>
                      <Dropdown.Item>
                        <Dropdown text="Car Insurance" simple direction="right">
                          <Dropdown.Menu>
                            <Dropdown.Item>
                              <Link
                                to={{
                                  pathname:
                                    "https://general.bajajallianz.com/MotorInsurance/onlineportal/motorNew/indexCar.jsp?p_product_code=1801&src=CBM_0574887",
                                }}
                                target="_blank"
                                style={{ color: "black" }}
                              >
                                Private Car - Package Policy
                              </Link>
                            </Dropdown.Item>
                            <Dropdown.Item>
                              <Link
                                to={{
                                  pathname:
                                    "https://general.bajajallianz.com/MotorInsurance/onlineportal/motorTP/loadData.do?p_product_type=RPV&src=CBM_0624110",
                                }}
                                target="_blank"
                                style={{ color: "black" }}
                              >
                                Private Car TP Policy
                              </Link>
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </Dropdown.Item>
                      <Dropdown.Item>
                        <Dropdown
                          text="Health Insurance"
                          simple
                          direction="right"
                        >
                          <Dropdown.Menu>
                            <Dropdown.Item>
                              <Link
                                to={{
                                  pathname:
                                    "https://general.bajajallianz.com/Insurance/healthGuard/loadHgDtls.do?product=EC&src=CBM_0574886",
                                }}
                                target="_blank"
                                style={{ color: "black" }}
                              >
                                Extra Care Plus
                              </Link>
                            </Dropdown.Item>
                            <Dropdown.Item>
                              <Link
                                to={{
                                  pathname:
                                    "https://general.bajajallianz.com/BagicNxt/CBMQR/cbmUrlLink.do?imd_code=541E310765344816ADE40700F1F3ED90&sub_imd_code=D57FA696FE053D8E&p_product_code=8407&p_location_code=BC86ED5A0F3E335B&p_agnet_category=25E070F074B76603EAE9E9A86B0F0EC4&p_encr_flag=Y",
                                }}
                                target="_blank"
                                style={{ color: "black" }}
                              >
                                Critical Illness Insurance
                              </Link>
                            </Dropdown.Item>
                            <Dropdown.Item>
                              <Link
                                to={{
                                  pathname:
                                    "https://general.bajajallianz.com/Insurance/healthGuard/loadHgDtls.do?src=CBM_0564776",
                                }}
                                target="_blank"
                                style={{ color: "black" }}
                              >
                                Health Guard Policy
                              </Link>
                            </Dropdown.Item>
                            <Dropdown.Item>
                              <Link
                                to={{
                                  pathname:
                                    "https://general.bajajallianz.com/BagicNxt/CBMQR/cbmUrlLink.do?imd_code=541E310765344816ADE40700F1F3ED90&sub_imd_code=D57FA696FE053D8E&p_product_code=8442&p_location_code=BC86ED5A0F3E335B&p_agnet_category=25E070F074B76603EAE9E9A86B0F0EC4&p_encr_flag=Y",
                                }}
                                target="_blank"
                                style={{ color: "black" }}
                              >
                                Health Infinity Policy
                              </Link>
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </Dropdown.Item>
                      <Dropdown.Item>
                        <Dropdown
                          text="Personal Accident"
                          simple
                          direction="right"
                        >
                          <Dropdown.Menu>
                            <Dropdown.Item>
                              <Link
                                to={{
                                  pathname:
                                    "https://general.bajajallianz.com/BagicNxt/CBMQR/cbmUrlLink.do?imd_code=541E310765344816ADE40700F1F3ED90&sub_imd_code=D57FA696FE053D8E&p_product_code=6403&p_location_code=BC86ED5A0F3E335B&p_agnet_category=25E070F074B76603EAE9E9A86B0F0EC4&p_encr_flag=Y",
                                }}
                                target="_blank"
                                style={{ color: "black" }}
                              >
                                Premium Personal Guard
                              </Link>
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </Dropdown.Item>
                      <Dropdown.Item>
                        <Dropdown
                          text="Travel Insurance"
                          simple
                          direction="right"
                        >
                          <Dropdown.Menu>
                            <Dropdown.Item>
                              <Link
                                to={{
                                  pathname:
                                    "https://www.bajajallianz.com/travel-insurance-online/buy-online.html?src=CBM_0564777",
                                }}
                                target="_blank"
                                style={{ color: "black" }}
                              >
                                Travel Companion
                              </Link>
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </Dropdown.Item>
                      <Dropdown.Item>
                        <Dropdown
                          text="Cyber Insurance"
                          simple
                          direction="right"
                        >
                          <Dropdown.Menu>
                            <Dropdown.Item>
                              <Link
                                to={{
                                  pathname:
                                    "https://general.bajajallianz.com/BagicNxt/CBMQR/cbmUrlLink.do?imd_code=541E310765344816ADE40700F1F3ED90&sub_imd_code=D57FA696FE053D8E&p_product_code=3313&p_location_code=BC86ED5A0F3E335B&p_agnet_category=25E070F074B76603EAE9E9A86B0F0EC4&p_encr_flag=Y",
                                }}
                                target="_blank"
                                style={{ color: "black" }}
                              >
                                Individual Cyber Safe
                              </Link>
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </List.Content>
              </List.Item>

              <List.Item
                className={classes.Items}
                as="a"
                content="Online Investment"
              />
              <List.Item>
                <Link
                  className={classes.Items}
                  to={{
                    pathname:
                      "http://mf.sanchitinvestment.in/software/custlogin.asp",
                  }}
                  target="_blank"
                >
                  Login For Mutual Fund
                </Link>
              </List.Item>
              <List.Item as="a">
                <Link className={classes.Items} to="/AboutUs">
                  About Us
                </Link>
              </List.Item>
              <List.Item as="a">
                <Link className={classes.Items} to="/ContactUs">
                  Contact Us
                </Link>
              </List.Item>
            </List>
          </Grid.Column>
        </MediaQuery>
      </nav>
    </Grid>
  );
}

export default BottomNavBar;
