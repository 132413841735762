import React from "react";
import SimpleImageSlider from "react-simple-image-slider";
import { Icon, Button } from "semantic-ui-react";
function ImageSlider() {
  const images = [
    { url: "../images/slider_img.jpg" },
    { url: "../images/slider_img2.jpg" },
    { url: "../images/slider_img3.jpg" },
  ];
  return (
    <div class="item active">
      <SimpleImageSlider
        bgColor="#00000080"
        width={1600}
        height={600}
        images={images}
        useGPURender
      ></SimpleImageSlider>
      <div class="overlay">
        <div class="carousel-caption">
          <h3>Welcome to the Profession of</h3>
          <h1>Life Insurance Consultant</h1>
          {/* <!-- <h1 class="second_heading">Life Insurance Consultant</h1>-->
                                <!--<p>Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit,</p>--> */}
          <a href="services.html" class="btn know_btn">
            know more
          </a>
          {/* <!-- <a  class="btn know_btn">view project</a>--> */}
        </div>
      </div>
    </div>
  );
}

export default ImageSlider;
