import React from "react";
import {
  Container,
  Divider,
  Dropdown,
  Grid,
  Header,
  Image,
  List,
  Menu,
  Segment,
  Icon,
} from "semantic-ui-react";
import classes from "./footer.module.css";
function Footer() {
  return (
    <div className={classes.Footer}>
      <Segment
        className={classes.Segment}
        vertical
        style={{ padding: "5em 0em" }}
      >
        <Container>
          <Grid stackable divided>
            <Grid.Column width={5}>
              <Header
                style={{ color: "white", "padding-bottom": "10px" }}
                as="h4"
                content="About Company"
                size="huge"
              />
              <p>
                Sanchit Investment is a money managing house associated in 1992
                by Mr. Rajeev Kumar Agarwal (B.Com, MBA, C.M. Club Member LIC,
                AMFI Certified, UMAX Club Member by UIIC)
              </p>
              <List horizontal size="huge" link>
                <List.Item as="a">
                  <Icon color="yellow" circular inverted name="facebook f" />
                </List.Item>
                <List.Item as="a">
                  <Icon color="yellow" circular inverted name="twitter" />
                </List.Item>
                <List.Item as="a">
                  <Icon color="yellow" circular inverted name="linkedin" />
                </List.Item>
                <List.Item as="a">
                  <Icon color="yellow" circular inverted name="youtube" />
                </List.Item>
              </List>
            </Grid.Column>
            <Grid.Column width={3}>
              <Header
                style={{ color: "white", "padding-bottom": "10px" }}
                as="h4"
                content="Explore Link"
                size="huge"
              />
              <List relaxed animated size="big" link>
                <List.Item
                  icon="triangle right"
                  content="Home"
                  style={{ color: "white" }}
                />
                <List.Item
                  icon="triangle right"
                  content="About Us"
                  style={{ color: "white" }}
                />
                <List.Item
                  icon="triangle right"
                  content="Online Investment"
                  style={{ color: "white" }}
                />
                <List.Item
                  icon="triangle right"
                  content="Services"
                  style={{ color: "white" }}
                />
                <List.Item
                  icon="triangle right"
                  content="Contact Us"
                  style={{ color: "white" }}
                />
              </List>
            </Grid.Column>
            <Grid.Column width={5}>
              <Header
                style={{ color: "white", "padding-bottom": "10px" }}
                as="h4"
                content="Contact Us"
                size="huge"
              />
              <List relaxed="very" size="big">
                <List.Item
                  icon="map marker alternate"
                  content="12/474,Sector-12,Indira Nagar Lucknow-226016"
                  style={{ color: "white" }}
                />
                <List.Item
                  icon="mail"
                  content="rajeevrk30@gmail.com"
                  style={{ color: "white" }}
                />
                <List.Item
                  icon="phone"
                  content="9839039819 , 7007541284"
                  style={{ color: "white" }}
                />
              </List>
            </Grid.Column>
            <Grid.Column width={3}>
              <Header
                style={{ color: "white", "padding-bottom": "10px" }}
                as="h4"
                content="Branch Office"
                size="huge"
              />
              <List relaxed size="large" link>
                <List.Item
                  icon="map marker alternate"
                  content="T-11/ 204 ,Bhagiratha Enclave Awadh Vihar Yojna Shahid
                        Path,Lucknow"
                  style={{ color: "white" }}
                />
              </List>
            </Grid.Column>
          </Grid>

          {/*  <Divider inverted section /> */}
          {/*  <Image centered size="mini" src="/logo.png" />
          <List horizontal inverted divided link size="small">
            <List.Item as="a" href="#">
              Site Map
            </List.Item>
            <List.Item as="a" href="#">
              Contact Us
            </List.Item>
            <List.Item as="a" href="#">
              Terms and Conditions
            </List.Item>
            <List.Item as="a" href="#">
              Privacy Policy
            </List.Item>
          </List> */}
        </Container>
      </Segment>
    </div>
  );
}

export default Footer;
